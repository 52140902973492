






























































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import EventViewer from "../EventViewer.vue";
const BtnAddAppointment = () =>
  import(
    /* webpackChunkName: "add-appointment" */ "../buttons/BtnAddAppointment.vue"
  );

import { MonthItem, monthsNames } from "@/utils/monthsNames";
import { TranslateResult } from "vue-i18n";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import timeHhMm from "@/utils/timeHhMm";

import "./style.scss";
import Appointment from "@/models/Appointment";
import moment from "moment";
// import { getAPI } from "@/api/axios-base";
import { RolLabel } from "@/utils/rolesStatus";
import { getAPI } from "@/api/axios-base";
import { AxiosResponse } from "axios";
import { notifyError } from "@/components/Notification";
import { utilMixin } from "@/utils/mixins";

interface Event {
  name: string;
  start: Date;
  end: Date;
  color: string;
  timed: boolean;
}

export default Vue.extend({
  name: "crm-calendar",
  components: { EventViewer, BtnAddAppointment },
  mixins: [utilMixin],
  data() {
    return {
      type: "month",
      types: [
        {
          label: this.$t("labelMonth"),
          value: "month",
          icon: "mdi-calendar-month",
        },
        {
          label: this.$t("labelWeek"),
          value: "week",
          icon: "mdi-calendar-week",
        },
        {
          label: this.$t("labelDay"),
          value: "day",
          icon: "mdi-calendar-text",
        },
        {
          label: this.$t("label4Day"),
          value: "4day",
          icon: "mdi-calendar-range",
        },
      ],
      mode: "column",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      date: "",
      events: [],
      colors: [
        "indigo darken-1",
        "deep-purple darken-1",
        "cyan darken-1",
        "orange darken-1",
        "grey darken-1",
      ],
      year: 0,
      month: "",
      // For drag & drop
      dragEvent: null,
      dragStart: null,
      dragTime: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      // Event details
      openDetails: false,
      appointmenttypes: [],

      filterRequest: {
        dateRange: {
          date1: "",
          date2: "",
        },
        offset: 0,
        limit: 100,
        doctorUuid: null,
        patientUuid: null,
        typeId: null,
      },

      selectedEvent: {},
      selectedElement: null,
      RolLabel: RolLabel,
      // Event details on hover
      loading: false,
      event: null,
      patient: null,
      loadingPatientData: false,
      appointmetsFiltered: [],
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),
    labelDate(): string {
      return `${this.year} ${this.month}`;
    },

    total(): number {
      if (
        (this as any).event === null ||
        (this as any) === undefined ||
        this.patient === null ||
        this.patient === undefined ||
        (this as any).event.data.procedure == null ||
        (this as any).event.data.procedure == undefined
      )
        return 0;

      const procedure = (this as any).patient.proceduresEntity.find(
        (procedure: any) =>
          procedure.id == (this as any).event.data.procedure.id
      );
      if (procedure === undefined || procedure === null) return 0;

      // Tomar el total según el tipo de procedimiento
      if ((this as any).event.data.procedure.procedure_type === "Surgery") {
        if (procedure.contract == null || procedure.contract == undefined)
          return 0;
        return procedure.contract.total;
      } else if (
        (this as any).event.data.procedure.procedure_type === "Treatment"
      ) {
        return procedure.totalTreatment;
      }

      return 0;
    },
    deb(): number {
      if (
        this.event == null ||
        this.event == undefined ||
        this.patient == null ||
        this.patient == undefined ||
        (this as any).event.data.procedure == null ||
        (this as any).event.data.procedure == undefined
      )
        return 0;

      const procedure = (this as any).patient.proceduresEntity.find(
        (procedure: any) =>
          procedure.id == (this as any).event.data.procedure.id
      );
      if (procedure === undefined || procedure === null) return 0;

      const payments = procedure.payments.reduce(
        (total: number, current: any) => total + parseFloat(current.amount),
        0
      );

      return 0;
    },
  },
  watch: {
    // appointments(val: any[]) {
    //   (this as any).appointmetsFiltered = val;
    //   this.filterAppoinments(this.appointmetsType);
    // },
  },
  async mounted() {
    this.setToday();
    this.listAppointmentTypes();
    await this.getAppointment();
  },
  methods: {
    ...mapActions("crmAppointmentsModule", [
      "actListAppointment",
      "actFilterAppointment",
    ]),
    ...mapMutations("crmAppointmentsModule", ["mutDate"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),

    listAppointmentTypes() {
      this.loading = true;
      getAPI(`/appoiment-type/list`)
        .then((res) => {
          this.appointmenttypes = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    setCurrentDate({ year, month }: { year: number; month: number }): void {
      this.year = year;
      (this.month as TranslateResult) = (
        monthsNames[month - 1] as MonthItem
      ).name;
    },
    clearData() {
      this.selectedElement = null;
      this.selectedEvent = {};
    },

    setToday(): void {
      const now = new Date();

      this.setCurrentDate((this.$refs.calendar as any).getNow());
      this.date = now.toLocaleDateString();
    },
    showDay() {
      this.type = "day";
    },
    closedEvent() {
      (this.$refs.eventviewer as any).close();
      this.openDetails = false;
    },
    showEvent({ nativeEvent, event }: any): void {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.openDetails = true;
        }, 10);
      };

      if (this.openDetails) {
        this.openDetails = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    editAppointment(event: Appointment) {
      (this as any).$refs.addUpdateAppointment.setToEdit(event);
    },

    getAppointment() {
      this.loading = true;

      let body: any = Object.assign({}, this.filterRequest);

      body = this.cleanNull(body);

      getAPI
        .post("/patient/listDates", body)
        .then((res) => {
          this.loading = false;
          let temp = res.data.result;

          (this as any).appointmetsFiltered = temp;

          (this as any).appointmetsFiltered = this.appointmetsFiltered.map(
            (app: any) => {
              const color = (this as any).getColorsNumber(
                app.appoitmentType.id
              );
              return {
                name: app.patient.fullname,
                start: new Date(app.initialTime),
                data: app,
                end: new Date(app.endTime),
                color: color,
              };
            }
          );
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    onChange({ start, end }: any): void {
      (this as any).filterRequest.dateRange.date1 = new Date(
        start.date
      ).toISOString();
      (this as any).filterRequest.dateRange.date2 = new Date(
        end.date
      ).toISOString();
      this.getAppointment();
      this.mutDate(start.date);
    },

    getNameProcedure(procedure: any) {
      if (procedure.treatmentType) {
        return "T";
      } else {
        return "S";
      }
    },

    eventSummary(event: any) {
      // ${moment(timeHhMm(event.start), "hh:mm").format("hh:mm a")}

      return `<b>${this.getpatientN}: ${event.data.patient.fullname}</b><br/>
      <b>Doctor: ${
        event.data.doctor ? event.data.doctor.fullname : ""
      }</b> <br/>
      <b>Appointment Type: ${event.data.appoitmentType.name}<b/> <br/>
      <b> ${
        event.data.procedure && event.data.procedure.surgeryType
          ? "Procedure: " + event.data.procedure.surgeryType.name
          : event.data.procedure && event.data.procedure.treatmentType
          ? "Procedure: " + event.data.procedure.treatmentType.name
          : ""
      }<b/>
      <br/> ${moment(timeHhMm(event.start), "hh:mm").format(
        "hh:mm a"
      )} to ${moment(timeHhMm(event.end), "hh:mm").format("hh:mm a")}`;
    },
    eventDetails(event: any) {
      this.loadingPatientData = true;

      if (event.data) {
        this.event = event;
        getAPI(`/patient/getPatient/${event.data.patient.uuid}`).then(
          (response: AxiosResponse) => {
            this.loadingPatientData = false;
            this.patient = response.data;
          }
        );
      }
    },

    cleanNull(obj: any) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
});
